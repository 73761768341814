import { PlanOfCareEnum } from '../../popups/plan-of-care-popup/plan-of-care-popup.component';

export interface ConfirmPopupResModel {
  data: boolean;
  saveState?: any;
}

export interface PlanOfCarePopupCloseModel {
  data: PlanOfCareEnum;
  saveState?: any;
}

export interface AideType {
  key: string;
  name: string;
}

export interface HiringStatusesModel {
  key: string;
  name: string;
}

export enum HiringStatusesEnum {
  Hired = 'Hired',
  Terminated = 'Terminated',
}

export enum ExtraValidationDate {
  min = '01/01/1900',
  max = '12/31/2300',
}

export type ViventiumStatusType = 'not_sync' | 'not sync' | 'sync' | 'error' | 'sending';
